<template>
    <div class="login-box">
        <div class="login-logo">
            <img src="@/assets/logo.svg" alt="ANASAP Logo">
        </div>
        <div class="login-box-body">
            <h1 class="login-box-msg">Přihlaste se pro vstup</h1>
            <form>
            <div id="email" class="form-group has-feedback" v-bind:class="{'has-error': emailError}">
                <input type="email" v-on:input="clearErrors" v-model="email" class="form-control" placeholder="Email">
                <span class="glyphicon glyphicon-user form-control-feedback"></span>
            </div>
            <div id="password" class="form-group has-feedback" v-bind:class="{'has-error': passwordError}">
                <input type="password" v-on:input="clearErrors" v-model="password" class="form-control" placeholder="Heslo">
                <span class="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <p class="text-red text-center">{{ errorMessage }}</p>
            <div class="register-button-container">  
                <div class="text-center">
                    <a href="/#/register" class="anasap-link">Registrace</a>
                </div>
                <div><button type="submit" @click="handleSubmit" class="ml-auto anasap-btn" :disabled="!password || !email">Přihlásit</button></div>
            </div>
            </form>
        </div>
    </div>
</template>

<script>
    import api from '../store/classes/api'; 

    const name = 'Login';
    export default {
        name,
        data() {
            return {
                email: "",
                password: "",
                errorMessage: "",
                passwordError: false,
                emailError: false 
            }
        },
        methods: {
            passwordReset() {
                this.$alerts.message('Změna hesla po zapomenutí.', 'Zašlete ze svého emailu žádost o změnu na email sales@anasap.cz.', 'info');
            },
            async handleSubmit(e) {
                e.preventDefault();

                try
                {
                    let response = await api.login(this.email, this.password);

                    if (response === undefined)
                    {
                        throw {code: "EXCEPTION", message: "An exception occured."};
                    }

                    this.$router.push({name: 'Dashboard'});
                }
                catch (error)
                {
                    this.errorMessage = "";
                    this.emailError = false;
                    this.passwordError = false;

                    var errorCode = (error.response === undefined ? error.code : error.response.data.code)

                    switch (errorCode)
                    {
                        case "USER_NOT_EXIST":
                            this.emailError = true;
                            this.errorMessage = "Uživatel se zadanou emailovou adresou neexistuje!";
                            break;
                        case "WRONG_PASSWORD":
                            this.passwordError = true;
                            this.errorMessage = "Nesprávné heslo!";
                            break;
                        case "ACCOUNT_BLOCKED":
                            this.emailError = true;
                            this.errorMessage = "Váš účet byl zablokován, prosím, obraťte se na podporu aplikace.";
                            break;
                    }
                }  
            },
            clearErrors() {
                this.errorMessage = "";
                this.emailError = false;
                this.passwordError = false;
            }
        }
    }
</script>

<style>
    @import "../assets/scss/login.scss";
</style>
